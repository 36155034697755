import moment from 'moment';
import * as methods from './methods';
import {
  PropertyListResponse, CalendarResponse, ReservationDetailsResponse, CreateManualBlockRequest, CreateNewReservationRequest,
  CreateNewReservationResponse, CreateOwnerBlockRequest, CreateOwnerBlockResponse, UpdateAutomationStatusResponse, CreateJobRequest, ContractorsResponse, OwnerResponse,
  ImagesResponse, AccommodationFeeResponse, ReservationHoverInfoResponse
} from './type';

// Request for fetching properties
export const fetchProperties = async (importantOrgId: string): Promise<PropertyListResponse> => {
  try {
    const response = await methods.get('api/v1/multi-calendar/properties', {
      importantOrgId: importantOrgId,
    });

    return response.data;

  } catch (error) {
    console.error('Error fetching properties:', error);
    throw error;
  }
};

export const authenticateimportantOrgId = async (token: string, userId: string, importantOrgId: string): Promise<boolean> => {
  try {
    const response = await methods.post('api/v1/multi-calendar/authenticate', {
      token: token,
      userId: userId,
      importantOrgId: importantOrgId,
    });

    return response.data;

  } catch (error) {
    console.error('Error in Authenticating API: ', error);
    throw error;
  }
};

// Request for fetching associated contractors
export const fetchAssociatedContractors = async (importantOrgId: string, propertyId: string): Promise<ContractorsResponse> => {
  try {
    const response = await methods.get('api/v1/multi-calendar/associated-contractors', {
      importantOrgId,
      propertyId
    });

    return response.data;

  } catch (error) {
    console.error('Error fetching associated contractors:', error);
    throw error;
  }
};

export const fetchPropertiesAvailability = async (importantOrgId: string, startDate: string, endDate: string, city: string): Promise<PropertyListResponse> => {
  try {
    const response = await methods.get('api/v1/multi-calendar/properties', {
      importantOrgId: importantOrgId,
      startDate,
      endDate,
      city
    });

    return response.data || { data: [] };

  } catch (error) {
    console.error('Error fetching properties:', error);
    throw error;
  }
};

// Request for calendar data
export const fetchCalendarData = async (startDate: string, endDate: string, propertyIdList: string[]): Promise<CalendarResponse> => {

  try {
    const response = await methods.post('api/v3/bulk/getCalendarDateBYHotelIdAndDate', {
      startDate,
      endDate,
      propertyIdList
    });

    return response.data;

  } catch (error) {
    console.error('Error fetching calendar data:', error);
    throw error;
  }
};

// Fetch reservation details
export const fetchReservationDetails = async (reservationId: string): Promise<ReservationDetailsResponse> => {
  try {
    const response = await methods.get(`api/v1/multi-calendar/reservation/${reservationId}/details`);
    return response.data;
  } catch (error) {
    console.error('Error fetching reservation details:', error);
    throw error;
  }
};

// Toggle active status
export const updateAutomationStatus = async (
  automationItemId: string,
  isActive: boolean
): Promise<UpdateAutomationStatusResponse> => {
  try {
    const response = await methods.patch<{ isActive: boolean }>(
      `api/v1/automation/${automationItemId}/updateActiveStatus`,
      { isActive }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating automation status:', error);
    throw error;
  }
};

// Reservation:: manual, owner and new reservation

// New reservation
export const createNewReservation = async (
  requestData: CreateNewReservationRequest
): Promise<CreateNewReservationResponse> => {
  try {
    const response = await methods.post<CreateNewReservationRequest, undefined>(
      'api/v1/reservation/createNewReservation',
      requestData
    );
    return response.data;
  } catch (error) {
    console.error('Error creating new reservation:', error);
    throw error;
  }
};

// Manual block
export const createManualBlock = async (
  requestData: CreateManualBlockRequest
): Promise<CreateNewReservationResponse> => {
  try {
    const response = await methods.post<CreateManualBlockRequest, undefined>(
      'api/v1/reservation/createManualBlock',
      requestData
    );
    return response.data;
  } catch (error) {
    console.error('Error creating manual block:', error);
    throw error;
  }
};

// Owner block
export const createOwnerBlock = async (
  requestData: CreateOwnerBlockRequest
): Promise<CreateOwnerBlockResponse> => {
  try {
    const response = await methods.post<CreateOwnerBlockRequest, undefined>(
      'api/v1/reservation/createOwnerBlock',
      requestData,
      undefined
    );
    return response.data;
  } catch (error) {
    console.error('Error creating owner block:', error);
    throw error;
  }
};

// Create job
export const createJob = async (
  requestData: CreateJobRequest
): Promise<any> => {
  try {
    const response = await methods.post<CreateJobRequest, undefined>(
      'api/v2/items/create-task-mc',
      requestData
    );
    return response.data;
  } catch (error) {
    console.error('Error creating job:', error);
    throw error;
  }
};

// Request for fetching property owner
export const fetchPropertyOwner = async (propertyId: string): Promise<OwnerResponse> => {
  try {
    const response = await methods.get(`api/v1/multi-calendar/property/${propertyId}/owner`);
    return response.data;
  } catch (error) {
    console.error('Error fetching property owner:', error);
    throw error;
  }
};

// Fetch images for a specific item ID
export const fetchImages = async (itemsId: string | null): Promise<ImagesResponse> => {
  console.log("item id", itemsId);
  try {
    const response = await methods.get(`api/v1/multi-calendar/items/${itemsId}/images`);
    return response.data;
  } catch (error) {
    console.error('Error fetching images:', error);
    throw error;
  }
};

export const fetchAccommodationFee = async (
  checkInDate: string,
  checkOutDate: string,
  propertyId: string
): Promise<AccommodationFeeResponse> => {
  try {
    const response = await methods.post<{
      checkInDate: string;
      checkOutDate: string;
      propertyId: string;
    }, undefined>(
      'api/v1/reservation/getAccomodationFeeByReservation',
      {
        checkInDate,
        checkOutDate,
        propertyId
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching accommodation fee:', error);
    throw error;
  }
};


export const fetchAvailability = async (propertyId: string, startDate: string, endDate: string): Promise<{ reservationIsAvailable: boolean, alreadyExistingReservations: any[] }> => {
  try {
    const response = await methods.get(`api/v1/multi-calendar/property/${propertyId}/check-availability`, {
      startDate,
      endDate,
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching availability:', error);
    throw error;
  }
};

export const fetchCalendarDataVFour = async (
  startDate: string,
  endDate: string,
  importantOrgId: string,
  page: number = 1,
  limit: number = 5,
  city: string = '',
  useFreshData: boolean = false
) => {
  try {
    const response = await methods.post(
      `api/v1/multi-calendar/get-properties-with-reservations?useFreshData=${useFreshData}`,
      {
        startDate,
        endDate,
        importantOrgId,
        page,
        limit,
        city,
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching calendar data:', error);
    throw error;
  }
};

export const fetchTotalProperties = async (
  importantOrgId: string
) => {
  try {
    const response = await methods.get(`api/v1/multi-calendar/get-properties-count`, {
      importantOrgId,
    });

    return response.data.data;
  } catch (error) {
    console.error("Error fetching total properties count:", error);
    throw error;
  }
};

export const fetchReservationHoverInfo = async (reservationId: string): Promise<ReservationHoverInfoResponse> => {
  try {
    const response = await methods.get(`api/v1/multi-calendar/reservation/${reservationId}/hover-info`);
    return response.data;
  } catch (error) {
    console.error('Error fetching reservation hover info:', error);
    throw error;
  }
};

export const cancelReservation = async (reservationId: string): Promise<{ data: string; status: string; success: boolean }> => {
  try {
    const response = await methods.remove(`api/v1/reservation/deleteReservation/${reservationId}`, {});
    return response.data;
  } catch (error) {
    console.error('Error deleting reservation:', error);
    throw error;
  }
};


