import React from 'react';
import moment from 'moment';

import checkCircle from '../assets/check-circle.svg';
import clock from '../assets/clock-small.svg';
import homeDark from '../assets/home-dark.svg';
import { getJobLightIcon } from '../../utils/iconUtils';
import { Job, PropertyList } from '../../api/type';

type MultipleJobsTooltipProps = {
  jobs: Job[];
  property: PropertyList | undefined;
  onClose: () => void;
};

const MultipleJobsTooltip: React.FC<MultipleJobsTooltipProps> = ({
  jobs,
  property,
  onClose
}) => {
  if (!jobs.length) return null;

  return (
    <div
      className="tooltip-more"
      onClick={onClose}
    >
      <div className="tooltip-content">
        {jobs.map((job, index) => (
          <div key={index} className="reservation-tooltip-container">
            <div className="reservation-tooltip-info">
              <div className="reservation-icon">
                <img src={getJobLightIcon(job.jobType)} alt={job.jobType} />
              </div>
              <div className="reservation-info-tooltip">
                {job.status === 'finished' && (
                  <div className="confirmed-icon">
                    <img src={checkCircle} alt="Check Icon" />
                  </div>
                )}
                <div
                  className={`reservation-value ${job.status === 'Open'
                    ? 'not-confirmed'
                    : job.status === 'Pending'
                      ? 'ongoing'
                      : 'confirmed'
                    }`}
                >
                  {job.status === 'Open'
                    ? 'Open'
                    : job.status === 'Pending'
                      ? 'Pending'
                      : 'Completed'}
                </div>
                <div className="reservation-value-small">{job.jobType}</div>
              </div>
            </div>
          </div>
        ))}

        <div className="reservation-info">
          <div className="reservation-container">
            <div className="reservation-dates">
              <p>{moment(jobs[0].startDate).format('MMM D, YYYY')}</p>
              <div className="time-info">
                <span className="mr-3">
                  <img src={clock} alt="Clock Icon" />{' '}
                  {moment(jobs[0].startDate).utc().format('h:mm A')}
                  <span className="mx-1">-</span>
                  <img src={clock} alt="Clock Icon" />{' '}
                  {moment(jobs[0].endDate).utc().format('h:mm A')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="property-dark-row">
          <div className="property-dark">
            <div className={property?.image ? 'listing-image' : 'listing-image-none'}>
              <img src={property?.image || homeDark} alt={property?.name || 'Property'} />
            </div>
            <div>
              <h3>{property?.name}</h3>
              <p>{property?.address}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleJobsTooltip;
