import React, { useMemo, useState, useCallback, useEffect, useContext } from "react";
import { useQueryClient, useQueries } from "react-query";
import moment from "moment";
import CustomMonthDropdown from "./dropdown/CustomMonthDropdown";
import leftIcon from "./assets/arrow-left.svg";
import rightIcon from "./assets/arrow-right.svg";
import calendarCheck from "./assets/calendar-check.svg";
import { fetchTotalProperties } from "../api/request";
import { ImportantOrgContext } from "../App";
import { CalendarPMS, Listing as ListingType, PropertyList, Job } from "../api/type";
import SingleViewCalendar from "./single_view/SingleViewCalendar";
import PropertyDropdown from "./modals/PropertyModal";

interface Day {
  date: string;
  dayOfWeek: number;
  day: number | null;
}

interface CalendarDataPage {
  calendarData: ListingType[];
  properties: PropertyList[];
  propertiesCount: number | undefined;
}

const generateMonthOptions = () => {
  const startMonth = moment("2024-01-01");
  const endMonth = moment("2025-12-31");
  const months = [];
  const current = startMonth.clone();

  while (current.isSameOrBefore(endMonth)) {
    months.push(current.format("MMMM YYYY"));
    current.add(1, "month");
  }

  return months;
};

const SingleViewCalendarApp: React.FC = () => {
  const importantOrgId = useContext(ImportantOrgContext);
  const [currentMonth, setCurrentMonth] = useState<string>(moment().format("MMMM YYYY"));
  const [selectedProperty, setSelectedProperty] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [totalProperties, setTotalProperties] = useState<number | null>(null);
  const [limit] = useState<number>(10);
  const [city, setCity] = useState<string>("");
  const [pagesToQuery, setPagesToQuery] = useState<number[]>([]);

  const monthOptions = useMemo(() => generateMonthOptions(), []);
  const currentDate = moment().format("YYYY-MM-DD");

  const [monthName, year] = currentMonth.split(" ");
  const startOfMonth = moment(`${monthName} ${year}`, "MMMM YYYY")
    .startOf("month")
    .format("YYYY-MM-DD");
  const endOfMonth = moment(`${monthName} ${year}`, "MMMM YYYY")
    .endOf("month")
    .format("YYYY-MM-DD");

  const daysInMonth = useMemo(() => {
    const [monthName, year] = currentMonth.split(" ");
    const startOfMonth = moment(`${monthName} ${year}`, "MMMM YYYY");
    const daysInMonth = startOfMonth.daysInMonth();
    const firstDayOfWeek = startOfMonth.day();

    const days: Day[] = [];

    for (let i = 0; i < firstDayOfWeek; i++) {
      days.push({ date: "", dayOfWeek: i, day: null });
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const date = startOfMonth.clone().add(i - 1, "days");
      days.push({
        date: date.format("YYYY-MM-DD"),
        dayOfWeek: date.day(),
        day: date.date(),
      });
    }

    while (days.length % 7 !== 0) {
      days.push({ date: "", dayOfWeek: days.length % 7, day: null });
    }

    return days;
  }, [currentMonth]);

  useEffect(() => {
    const fetchAndSetTotalProperties = async () => {
      try {
        setLoading(true);
        const response = await fetchTotalProperties(importantOrgId);
        const total = response || 0;
        setTotalProperties(total);

        const pages = Math.ceil(total / limit);
        setPagesToQuery(Array.from({ length: pages }, (_, index) => index + 1));

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching total properties:", error);
      }
    };

    if (importantOrgId) {
      fetchAndSetTotalProperties();
    }
  }, [importantOrgId, city, limit]);

  const fetchCalendarDataPage = ({
    page,
    startDate,
    endDate,
    city,
    importantOrgId,
    limit,
  }: {
    page: number;
    startDate: string;
    endDate: string;
    city: string;
    importantOrgId: string;
    limit: number;
  }): Promise<CalendarDataPage> => {
    if (!startDate || !endDate) {
      const [monthName, year] = currentMonth.split(" ");
      const parsedYear = parseInt(year, 10);
      const parsedMonth = parseInt(moment().month(monthName).format("M"), 10);
      startDate = new Date(Date.UTC(parsedYear, parsedMonth - 1, 1))
        .toISOString()
        .split("T")[0];
      endDate = new Date(Date.UTC(parsedYear, parsedMonth, 0))
        .toISOString()
        .split("T")[0];
    }

    if (!page) {
      page = 2;
    }
    return new Promise((resolve, reject) => {
      const worker = new Worker(new URL("../api/worker.js", import.meta.url));

      worker.postMessage({
        action: "fetchPage",
        startDate,
        endDate,
        importantOrgId,
        page,
        limit,
        city,
        useFreshData: false,
      });

      worker.onmessage = (event) => {
        const { type, data, error } = event.data;

        if (type === "data") {
          worker.terminate();
          if (!data) {
            reject(new Error("No data available in calendar response."));
          }

          const { properties = [], reservations = [] } = data;
          const totalProperties = data.totalProperties;

          const mappedData: ListingType[] = properties.map((property: PropertyList) => {
            const calendarItem = reservations.find(
              (cd: ListingType) => cd.calendarPropertyId === property.id
            );
            return {
              calendarPMSList: calendarItem ? calendarItem.calendarPMSList : [],
              calendarPropertyId: property.id,
              propertyAddress: property.address,
              propertyTitle: property.name,
              jobOccurrenceList: calendarItem ? calendarItem.jobOccurrenceList : [],
            };
          });

          resolve({
            calendarData: mappedData,
            properties: properties,
            propertiesCount: totalProperties,
          });
        } else if (type === "error") {
          worker.terminate();
          reject(new Error(error));
        }
      };

      worker.onerror = (error) => {
        worker.terminate();
        reject(new Error(error.message));
      };
    });
  };

  const queries = useQueries(
    totalProperties !== null
      ? pagesToQuery.map((page) => ({
        queryKey: ["calendarData", currentMonth, city, page],
        queryFn: () =>
          fetchCalendarDataPage({
            page,
            startDate: startOfMonth,
            endDate: endOfMonth,
            city,
            importantOrgId,
            limit,
          }),
        enabled: !!importantOrgId,
      }))
      : [
        {
          queryKey: ["calendarData"],
          enabled: false,
        },
      ]
  );

  const allCalendarData = queries
    .filter((q) => q.data)
    .flatMap((q) => q.data?.calendarData ?? []);

  const allProperties = queries
    .filter((q) => q.data)
    .flatMap((q) => q.data?.properties ?? []);

  const isLoading = queries.every((q) => q.isLoading);

  const isFetching = queries.some((q) => q.isFetching);

  useEffect(() => {
    if (allProperties.length > 0 && !selectedProperty) {
      setSelectedProperty(allProperties[0].id);
    }
  }, [allProperties, selectedProperty]);

  const filteredCalendarData = useMemo(() => {
    if (!selectedProperty) return [];
    return allCalendarData.filter(
      (calendarData) => calendarData.calendarPropertyId === selectedProperty
    );
  }, [selectedProperty, allCalendarData]);

  const handleMonthChange = useCallback(
    (direction: "prev" | "next") => {
      const newMonth = moment(currentMonth, "MMMM YYYY")
        .add(direction === "next" ? 1 : -1, "month")
        .format("MMMM YYYY");
      setCurrentMonth(newMonth);
    },
    [currentMonth]
  );

  const handleMonthSelectChange = useCallback((selectedMonth: string) => {
    setCurrentMonth(selectedMonth);
  }, []);

  const handlePropertySelect = useCallback((propertyId: string) => {
    setSelectedProperty(propertyId);
  }, []);

  return (
    <div className="single-calendar-page">
      <div className="single-page-header">
        <div className="d-flex">
          <div className="single-page-header-img-container">
            <img src={calendarCheck} alt="page-header-icon" />
          </div>
          <div>
            <h1>Calendar</h1>
            <p style={{ fontSize: "16px", fontWeight: 400, color: "#677372", lineHeight: "24px", fontFamily: "Inter", marginLeft: "3px" }}>View all the reservations and jobs assigned to you. </p>
          </div>
        </div>
      </div>
      <div className="single-calendar-container">
        <div className="calendar-header mb-16 mt-1">
          <PropertyDropdown
            allProperties={allProperties}
            handlePropertySelect={handlePropertySelect}
            selectedProperty={selectedProperty}
            isFetchingMore={isFetching}
          />

          <div className="date-filter-container mr-8">
            <div className="date-filter">
              <button
                className="navi-btn"
                onClick={() => handleMonthChange("prev")}
                style={{ cursor: "pointer" }}
              >
                <img src={leftIcon} alt="Previous Month" />
              </button>
              <CustomMonthDropdown
                options={monthOptions}
                selectedOption={currentMonth}
                onSelect={handleMonthSelectChange}
              />
              <button
                className="navi-btn"
                onClick={() => handleMonthChange("next")}
                style={{ cursor: "pointer" }}
              >
                <img src={rightIcon} alt="Next Month" />
              </button>
            </div>
          </div>
        </div>
        <SingleViewCalendar
          daysInMonth={daysInMonth}
          calendarData={filteredCalendarData[0] || null}
          currentDate={currentDate}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default SingleViewCalendarApp;
