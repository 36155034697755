import React, { useMemo } from "react";
import { CalendarPMS } from "../../api/type";
import moment from 'moment';
import { getImageForBookingChannel } from "../../utils/iconUtils";
import nightIcon from '../assets/night.svg';
import SkeletonRow from "../dropdown/SkeletonRow";

type DayCellProps = {
  date: string;
  calendarPMSMap: Record<string, CalendarPMS | undefined>;
  loading: boolean
  isInDisplayedMonth: boolean;
};

const SingleViewCell: React.FC<DayCellProps> = ({ date, calendarPMSMap, loading, isInDisplayedMonth }) => {
  const booking = calendarPMSMap[date];

  const previousDay = moment(booking?.reservationPMS?.arrivalDate).utc().subtract(1, 'day').format('YYYY-MM-DD');
  const previousBooking = calendarPMSMap[previousDay];
  const isAnyBlock = useMemo(() => {
    return (
      ['Manual Block', 'Advance Notice', 'After Block', 'Before Block', 'Rolling Window', 'Rolling window'].includes(
        booking?.reservationPMS?.otaName || ''
      )
    );
  }, [booking]);

  const previousSameDayDeparture = useMemo(() => {
    if (!booking?.reservationPMS) return false;

    if (!previousBooking || !previousBooking.reservationPMS) return false;

    const arrivalDate = moment(booking.reservationPMS.arrivalDate).utc();
    const departureDate = moment(previousBooking.reservationPMS.departureDate).utc();

    return arrivalDate.isSame(departureDate, 'day');
  }, [booking, calendarPMSMap]);

  const isPreviousBookingBlock = useMemo(() => {
    return (
      ['Manual Block', 'Advance Notice', 'After Block', 'Before Block', 'Rolling Window', 'Rolling window'].includes(
        previousBooking?.reservationPMS?.otaName || ''
      )
    );
  }, [previousBooking]);

  const previousBookingExists = useMemo(() => {
    if (!booking?.reservationPMS) return false;

    if (!previousBooking || !previousBooking.reservationPMS) return false;

    const arrivalDate = moment(booking.reservationPMS.arrivalDate).utc();
    const previousArrivalDate = moment(previousBooking.reservationPMS.arrivalDate).utc();
    const previousDepartureDate = moment(previousBooking.reservationPMS.departureDate).utc();

    return arrivalDate.isBetween(previousArrivalDate, previousDepartureDate, 'day')
  }, [booking, calendarPMSMap]);

  const normalizedDate = moment(date).format('YYYY-MM-DD');

  const sameDayDeparture = useMemo(() => {
    return (
      booking?.reservationPMS &&
      moment(booking.reservationPMS.arrivalDate).utc().isSame(booking.reservationPMS.departureDate, 'day')
    );
  }, [booking]);

  const isBookingStart = booking?.reservationPMS && moment(booking.reservationPMS.arrivalDate).utc().format('YYYY-MM-DD') === normalizedDate;
  const isBookingEnd = booking?.reservationPMS && moment(booking.reservationPMS.departureDate).utc().format('YYYY-MM-DD') === normalizedDate && !sameDayDeparture;


  return (
    <td className="table-day-cell h-112">
      <div className="single-date-text">{date ? new Date(date).getDate().toString().padStart(2, '0') : ""}</div>

      {loading && isInDisplayedMonth ? (
        <SkeletonRow type="calendar" />
      ) : (
        <div className="day-cell-container">
          {booking ? (
            booking.reservationPMS ? (
              <>
                {isAnyBlock ? (
                  <>
                    {previousSameDayDeparture && (
                      <div
                        className={`${isPreviousBookingBlock
                          ? 'manual-end-and-manual'
                          : 'booking-end-and-job'
                          }`}
                      >
                      </div>
                    )}

                    {previousBookingExists && (
                      <div
                        className={`${isPreviousBookingBlock
                          ? 'manual-block-single position-absolute'
                          : 'booking-single'
                          }`}
                      >
                      </div>
                    )}
                    <div
                      className={`manual-block-single z-index-1 
                        ${sameDayDeparture ? 'booking-same-day' :
                          isBookingStart ?
                            ('booking-start')
                            : ''} 
                        ${isBookingEnd ? ('booking-end') : ''}`
                      }
                    ></div>
                  </>
                ) : (
                  <>
                    {previousSameDayDeparture && (
                      <div
                        className={`${isPreviousBookingBlock
                          ? 'manual-end-and-manual'
                          : 'booking-end-and-job'
                          }`}
                      >
                      </div>
                    )}

                    {previousBookingExists && (
                      <div
                        className={`${isPreviousBookingBlock
                          ? 'manual-block-single position-absolute'
                          : 'booking-single'
                          }`}
                      >
                      </div>
                    )}

                    <div
                      className={`booking-single ${sameDayDeparture
                        ? ('booking-same-day')
                        : isBookingStart
                          ? ('booking-start')
                          : ''} ${isBookingEnd
                            ? ('booking-end')
                            : ''
                        }`}
                    >
                      {isBookingStart && (
                        <div className="booking-start-content">
                          <div className="circle-image">
                            <img src={getImageForBookingChannel(booking.reservationPMS.otaName ? booking.reservationPMS.otaName : '')} alt="Guest" />
                          </div>
                          <div className="booking-details">
                            <p className="guest-name">
                              {(() => {
                                const fullName = booking.reservationPMS.guestFullName || "";
                                const nameParts = fullName.split(" ");
                                var firstName = nameParts[0];
                                const lastNameInitial = nameParts[1] ? `${nameParts[1][0]}.` : "";
                                var firstNameModified = '';

                                return `${firstName} ${lastNameInitial}`;

                              })()}
                            </p>
                            <p className="guest-count-label">{booking.reservationPMS.totalGuests} Guests</p>
                            <p className="stay-details">{booking.reservationPMS.totalNights} Nights</p>
                          </div>
                        </div>
                      )}
                    </div>

                  </>
                )}
              </>
            ) : (
              <>
              </>
            )
          ) : (
            <>
            </>
          )}

        </div>
      )}
    </td>
  );
};

export default React.memo(SingleViewCell);
