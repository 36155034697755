import React, { useEffect, useState } from 'react';
import { PropertyList } from '../../api/type';
import moment from 'moment';
import { fetchImages } from '../../api/request';
import xIcon from '../assets/x.svg';
import homeDark from '../assets/home-dark.svg';
import linkIcon from '../assets/link.svg';

interface JobDetailSidebarProps {
  onClose: () => void;
  sideBar: boolean;
  jobDetail: {
    id: string;
    bubbleTaskId: string | null;
    jobType: 'Cleaning' | 'Maintenance' | 'Task' | 'Improvement' | 'Contact';
    status: string;
    date: string;
    startDate: string;
    endDate: string;
  };
  property: PropertyList | undefined;
}

const JobDetailSidebar: React.FC<JobDetailSidebarProps> = ({ onClose, sideBar, jobDetail, property }) => {
  const [images, setImages] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  useEffect(() => {
    const loadImages = async () => {
      try {
        const response = await fetchImages(jobDetail.bubbleTaskId);
        setImages(response.data || []);
      } catch (error) {
        setError('Error fetching images.');
      } finally {
        setLoading(false);
      }
    };

    loadImages();
  }, [jobDetail.bubbleTaskId]);

  const getDisplayValue = (value: any) => (value === null || value === undefined ? 'N/A' : value);


  const handleRedirect = (code: string, itemId: string) => {
    const baseUrl = 'https://mypropertystack.io';
    window.open(`${baseUrl}/items/${code}?item_id=${itemId}`, '_blank', 'noopener,noreferrer');
  };

  const startTime = moment(jobDetail.startDate);
  const endTime = moment(jobDetail.endDate);
  const duration = moment.duration(endTime.diff(startTime));
  const durationHours = Math.floor(duration.asHours());
  const durationMinutes = duration.minutes();

  return (
    <>
      <div className={`sidebar ${sideBar ? 'open' : ''}`}>
        <div className="sidebar-header">
          <h2>
            {jobDetail.jobType === 'Cleaning'
              ? 'Cleaning Job'
              : jobDetail.jobType === 'Maintenance'
                ? 'Maintenance Job'
                : jobDetail.jobType === 'Task'
                  ? 'Task Job'
                  : jobDetail.jobType === 'Improvement'
                    ? 'Improvement Job'
                    : jobDetail.jobType === 'Contact'
                      ? 'Contact Job'
                      : 'Job Detail'}
          </h2>

          <div className="sidebar-header-icons">
            <button className="out-button" onClick={() => handleRedirect(getDisplayValue(jobDetail.bubbleTaskId), getDisplayValue(jobDetail.id))}>
              <img src={linkIcon} alt="link icon" />
            </button>
            <button className="close-button" onClick={onClose}><img src={xIcon} alt="x-icon" className='x-icon' /></button>
          </div>
        </div>
        <div className="sidebar-body">
          <div className="sidebar-content-job">
            <div>
              <div className='booking-row-second'>
                <div className='booking-column'>
                  <div className="booking-label">Status</div>
                  <div className={`booking-value ${jobDetail.status === 'Open' ? 'not-confirmed' :
                    jobDetail.status === 'Pending' ? 'pending' :
                      'confirmed'
                    }`}>
                    {jobDetail.status === 'Open' ? 'Open' :
                      jobDetail.status === 'Pending' ? 'Pending' :
                        'Completed'}
                  </div>

                </div>
              </div>

              <div className='booking-row'>
                <div className='booking-column'>
                  <div className="booking-label">Date</div>
                  <div className="booking-value">{moment(jobDetail.startDate).format('MMM D, YYYY')}</div>
                </div>
              </div>

              <div className='booking-row'>
                <div className='booking-column'>
                  <div className="booking-label">Start Time</div>
                  <div className="booking-value">{moment(jobDetail.startDate).utc().format('h:mm A')}</div>
                </div>
              </div>

              <div className='booking-row'>
                <div className='booking-column'>
                  <div className="booking-label">Finish Time</div>
                  <div className="booking-value">{moment(jobDetail.endDate).utc().format('h:mm A')}</div>
                </div>
              </div>

              <div className='booking-row-second'>
                <div className='booking-column'>
                  <div className="booking-label">Duration</div>
                  <div className="booking-value">{`${durationHours} hour${durationHours !== 1 ? 's' : ''} ${durationMinutes} minute${durationMinutes !== 1 ? 's' : ''}`}</div>
                </div>
              </div>

              <div className='property-row mt-1'>
                <div className="property-nou">
                  <div className={property?.image ? "listing-image" : "listing-image-none"}>
                    <img src={property?.image || homeDark} alt={property?.name || 'Property'} />
                  </div>
                  <div>
                    <h3>{property?.name}</h3>
                    <p>{property?.address}</p>
                  </div>
                </div>
              </div>

              <div className='attachments'>
                <h3>Attachments</h3>
                {loading ? (
                  <div className="extrainfo-row">
                    <p>Loading...</p>
                  </div>
                ) : error ? (
                  <div>{error}</div>
                ) : images.length > 0 ? (
                  <div className='attachment-list'>
                    {images.map((image, index) => (
                      <div className='attachment' key={index} onClick={() => setSelectedImage(image)}>
                        <img src={image} alt="Attachment" />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className='booking-label'>No Attachments Found</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedImage && (
        <div className="img-modal" onClick={() => setSelectedImage(null)}>
          <div className="img-modal-content">
            <img src={selectedImage} alt="Enlarged Attachment" />
          </div>
        </div>
      )}
    </>
  );
};

export default JobDetailSidebar;
