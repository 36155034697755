import React from "react";
import { TableVirtuoso } from "react-virtuoso";
import { Listing as ListingType } from "../../api/type";
import SingleViewCell from "./SingleViewCell";
import moment from 'moment';

interface Day {
  date: string;
  dayOfWeek: number;
  day: number | null;
}

interface SingleViewCalendar {
  daysInMonth: Day[];
  calendarData: ListingType | null;
  currentDate: string;
  loading: boolean;
}

const SingleViewCalendar: React.FC<SingleViewCalendar> = ({
  daysInMonth,
  calendarData,
  currentDate,
  loading
}) => {
  const calendarPMSMap = React.useMemo(() => {
    const map: Record<string, any> = {};
    calendarData?.calendarPMSList?.forEach((item) => {
      map[item.date] = item;
    });
    return map;
  }, [calendarData]);

  const displayedMonth = React.useMemo(() => {
    const firstRealDay = daysInMonth.find((d) => d.date);
    const fallback = moment();
    if (!firstRealDay) return fallback;

    return moment(firstRealDay.date, "YYYY-MM-DD");
  }, [daysInMonth]);

  return (
    <TableVirtuoso
      style={{ height: '88vh', border: "solid 1px #ECEEE5", borderRadius: 12, width: "99.5%" }}
      data={Array.from({ length: Math.ceil(daysInMonth.length / 7) })}
      fixedHeaderContent={() => (
        <tr>
          <th style={{ width: 120, height: 60, background: "#143836", fontWeight: 600, color: "#F8F6EE" }}>
            Sunday
          </th>
          <th style={{ width: 120, height: 60, background: "#143836", fontWeight: 600, color: "white" }}>Monday</th>
          <th style={{ width: 120, height: 60, background: "#143836", fontWeight: 600, color: "#F8F6EE" }}>Tuesday</th>
          <th style={{ width: 120, height: 60, background: "#143836", fontWeight: 600, color: "#F8F6EE" }}>Wednesday</th>
          <th style={{ width: 120, height: 60, background: "#143836", fontWeight: 600, color: "#F8F6EE" }}>Thursday</th>
          <th style={{ width: 120, height: 60, background: "#143836", fontWeight: 600, color: "#F8F6EE" }}>Friday</th>
          <th style={{ width: 120, height: 60, background: "#143836", fontWeight: 600, color: "#F8F6EE" }}>Saturday</th>
        </tr>
      )}
      itemContent={(index) => {
        const start = index * 7;
        const week = daysInMonth.slice(start, start + 7);

        return (
          <>
            {week.map((day, dayIndex) => {
              const isInDisplayedMonth =
                day.date && moment(day.date).isSame(displayedMonth, "month");

              return (
                <SingleViewCell
                  key={dayIndex}
                  date={day.date}
                  calendarPMSMap={calendarPMSMap}
                  loading={loading}
                  isInDisplayedMonth={!!isInDisplayedMonth}
                />
              );
            })}
          </>
        );
      }}
    />
  );
};

export default SingleViewCalendar;

