import debounce from 'lodash.debounce';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useRef, useState, useTransition } from 'react';
import { generateDatesForMonth } from '../utils/functionUtils';
import Calendar from './multi_view/MultiViewCalendar';
import FindAvailabilityModal from './modals/FindAvailabilityModal';
import {
  CalendarPMS,
  Listing as ListingType,
  PropertyList,
  Job
} from '../api/type';

import { useQueryClient, useQueries } from 'react-query';

import { ImportantOrgContext } from '../App';
import leftIcon from './assets/arrow-left.svg';
import rightIcon from './assets/arrow-right.svg';
import CustomMonthDropdown from './dropdown/CustomMonthDropdown';
import BookingSidebar from './sidebars/BookingSidebar';
import JobDetailSidebar from './sidebars/JobDetailSidebar';
import JobSidebar from './sidebars/JobSidebar';
import MoreJobDetailSidebar from './sidebars/MoreJobDetailSidebar';
import ReservationSidebar from './sidebars/ReservationSidebar';
import { fetchTotalProperties } from '../api/request';

interface CalendarRef {
  scrollToCurrentDate: () => void;
  scrollToFirstDate: () => void;
  scrollToSpecificDate: (targetDate: string) => void;
  scrollToSpecificProperty: (propertyId: string) => void;
}

interface DateRange {
  startDate: string;
  endDate: string;
}

interface CalendarData {
  [date: string]: {
    id: string;
    date: string;
    endDate: string | null;
    price: number;
    propertyId: string;
    reservations: any | null;
    jobList: any[];
  };
}

interface AllCalendarData {
  [propertyId: string]: CalendarData;
}

const generateMonthOptions = () => {
  const startMonth = moment('2024-01-01');
  const endMonth = moment('2025-12-31');
  const months = [];
  const current = startMonth.clone();

  while (current.isSameOrBefore(endMonth)) {
    months.push(current.format('MMMM YYYY'));
    current.add(1, 'month');
  }

  return months;
};

interface CalendarDataPage {
  calendarData: ListingType[];
  properties: PropertyList[];
  propertiesCount: number | undefined;
}

const MultiViewCalendarApp: React.FC = () => {
  const importantOrgId = useContext(ImportantOrgContext);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredSearchTerm, setFilteredSearchTerm] = useState<string>('');
  const [dates, setDates] = useState<string[]>([]);
  const [currentMonth, setCurrentMonth] = useState<string>(moment().format('MMMM YYYY'));
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loadingSecond, setLoadingSecond] = useState<boolean>(false);

  const [limit] = useState<number>(10);
  const [pagesToQuery, setPagesToQuery] = useState<number[]>([]);

  const [selectedRange, setSelectedRange] = useState<DateRange>({ startDate: '', endDate: '' });
  const [city, setCity] = useState<string>('');
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [guestCount, setGuestCount] = useState<number | null>(null);

  const [selectedDate, setSelectedDate] = useState<string>(moment().format("MMMM YYYY"));
  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  const [isBookingSidebarOpen, setIsBookingSidebarOpen] = useState<boolean>(false);
  const [selectedBooking, setSelectedBooking] = useState<CalendarPMS | null>(null);
  const [selectedProperty, setSelectedProperty] = useState<PropertyList | undefined>(undefined);

  const [isJobSidebarOpen, setIsJobSidebarOpen] = useState<boolean>(false);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [currentJobs, setCurrentJobs] = useState<Job[]>([]);

  const [isReservationSidebarOpen, setIsReservationSidebarOpen] = useState<boolean>(false);
  const [isJobDetailSidebarOpen, setIsJobDetailSidebarOpen] = useState<boolean>(false);
  const [isMoreDetailSidebarOpen, setIsMoreDetailSidebarOpen] = useState(false);

  const [needToScrollToCurrentDate, setNeedToScrollToCurrentDate] = useState<boolean>(false);
  const [needToScrollToFirstDate, setNeedToScrollToFirstDate] = useState<boolean>(false);
  const [needToScrollToSpecificDate, setNeedToScrollToSpecificDate] = useState<boolean>(false);
  const [specificDate, setSpecificDate] = useState<string | undefined>();

  const [newlyAddedReservationId, setNewlyAddedReservationId] = useState<string | null>(null);
  const [newlyAddedJobId, setNewlyAddedJobId] = useState<string | null>(null);
  const [monthOptions] = useState<string[]>(generateMonthOptions());

  const [totalProperties, setTotalProperties] = useState<number | null>(null);

  const [jobs, setJobs] = useState<any[]>([]);

  const [isSearchLoading, startSearchTransition] = useTransition();

  const hasScrolledToToday = useRef(false);

  const calendarRef = useRef<CalendarRef>(null);

  const debouncedSetFilteredSearchTerm = useCallback(
    debounce((term: string) => {
      startSearchTransition(() => {
        setFilteredSearchTerm(term);
      });
    }, 500),
    []
  );

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    debouncedSetFilteredSearchTerm(event.target.value);
  }, [debouncedSetFilteredSearchTerm]);

  const scrollToToday = useCallback(() => {
    if (calendarRef.current) {
      calendarRef.current.scrollToCurrentDate();
    }
  }, []);

  const handleMonthChange = useCallback(
    (direction: 'prev' | 'next') => {
      hasScrolledToToday.current = false;
      const newMonth = moment(currentMonth, 'MMMM YYYY')
        .add(direction === 'next' ? 1 : -1, 'month')
        .format('MMMM YYYY');
      const [monthName, year] = newMonth.split(' ');

      setCurrentMonth(newMonth);
      setDates(generateDatesForMonth(Number(year), moment().month(monthName).month()));

      // if (moment().format('MMMM YYYY') !== newMonth) {
      //   setNeedToScrollToFirstDate(true);
      // } else {
      //   scrollToToday();
      // }
    },
    [currentMonth]
  );

  const handleMonthSelectChange = useCallback(
    (selectedMonth: string) => {
      hasScrolledToToday.current = false;
      const [monthName, year] = selectedMonth.split(' ');
      const monthIndex = moment().month(monthName).month();
      setCurrentMonth(selectedMonth);
      setDates(generateDatesForMonth(Number(year), monthIndex));

      // if (moment().format('MMMM YYYY') !== selectedMonth) {
      //   setNeedToScrollToFirstDate(true);
      // } else {
      //   scrollToToday();
      // }

    },
    [currentMonth, scrollToToday]
  );

  const handleTodayClick = useCallback(() => {
    hasScrolledToToday.current = false;
    const todayMonth = moment().format('MMMM YYYY');
    if (currentMonth !== todayMonth) {

      const currentYear = moment().year();
      const currentMonthIndex = moment().month();
      const monthName = moment().format('MMMM');
      const year = moment().format('YYYY');
      setCurrentMonth(todayMonth);
      setDates(generateDatesForMonth(currentYear, currentMonthIndex));

      setTimeout(() => {
        scrollToToday();
      }, 0);
    } else {
      scrollToToday();
    }
  }, [currentMonth, scrollToToday]);

  const toggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  useEffect(() => {
    const fetchAndSetTotalProperties = async () => {
      try {
        setLoadingSecond(true);
        const response = await fetchTotalProperties(importantOrgId);
        const total = response || 0;
        setTotalProperties(total);

        const pages = Math.ceil(total / limit);
        setPagesToQuery(Array.from({ length: pages }, (_, index) => index + 1));

        // console.log("array of pages", pagesToQuery);
        setLoadingSecond(false);
      } catch (error) {
        setLoadingSecond(false);
        console.error('Error fetching total properties:', error);
      }
    };

    if (importantOrgId) {
      fetchAndSetTotalProperties();
    }
  }, [importantOrgId, city, limit]);

  useEffect(() => {
    if (needToScrollToFirstDate && calendarRef.current) {
      calendarRef.current.scrollToFirstDate();
      setNeedToScrollToFirstDate(false);
    }
  }, [needToScrollToFirstDate]);

  useEffect(() => {
    if (needToScrollToCurrentDate && calendarRef.current) {
      calendarRef.current.scrollToCurrentDate();
      setNeedToScrollToCurrentDate(false);
    }
  }, [needToScrollToCurrentDate]);

  useEffect(() => {
    if (specificDate && needToScrollToSpecificDate && calendarRef.current) {
      calendarRef.current.scrollToSpecificDate(specificDate);
      setNeedToScrollToSpecificDate(false);
    }
  }, [needToScrollToSpecificDate, specificDate]);

  useEffect(() => {
    const [monthName, year] = currentMonth.split(' ');
    const monthIndex = moment().month(monthName).month();
    setDates(generateDatesForMonth(Number(year), monthIndex));
  }, [currentMonth]);

  // const fetchCalendarDataPage = ({
  //   page,
  //   startDate,
  //   endDate,
  //   city,
  //   importantOrgId,
  //   limit,
  // }: {
  //   page: number;
  //   startDate: string;
  //   endDate: string;
  //   city: string;
  //   importantOrgId: string;
  //   limit: number;
  // }): Promise<CalendarDataPage> => {
  //   if (!startDate || !endDate) {
  //     const [monthName, year] = currentMonth.split(' ');
  //     const parsedYear = parseInt(year, 10);
  //     const parsedMonth = parseInt(moment().month(monthName).format('M'), 10);
  //     startDate = new Date(Date.UTC(parsedYear, parsedMonth - 1, 1))
  //       .toISOString()
  //       .split('T')[0];
  //     endDate = new Date(Date.UTC(parsedYear, parsedMonth, 0))
  //       .toISOString()
  //       .split('T')[0];
  //   }

  //   return new Promise((resolve, reject) => {
  //     const worker = new Worker(new URL('../api/worker.js', import.meta.url));

  //     worker.postMessage({
  //       action: 'fetchPage',
  //       startDate,
  //       endDate,
  //       importantOrgId,
  //       page,
  //       limit,
  //       city,
  //       latitude,
  //       longitude,
  //       guestCount,
  //       useFreshData: false,
  //     });

  //     worker.onmessage = (event) => {
  //       const { type, data, error } = event.data;

  //       if (type === 'data') {
  //         worker.terminate();
  //         if (!data) {
  //           reject(new Error('No data available in calendar response.'));
  //         }

  //         const { properties = [], reservations = {}, totalProperties } = data;

  //         if (typeof reservations !== 'object' || reservations === null) {
  //           reject(new Error('Reservations must be a valid object.'));
  //           return;
  //         }

  //         const mappedData: ListingType[] = properties.map((property: PropertyList) => {
  //           const calendarItem = reservations[property.id];

  //           return {
  //             calendarPMSList: calendarItem ? calendarItem.calendarPMSList : [],
  //             calendarPropertyId: property.id,
  //             propertyAddress: property.address,
  //             propertyTitle: property.name,
  //             jobOccurrenceList: calendarItem ? calendarItem.jobOccurrenceList : [],
  //           };
  //         });

  //         resolve({
  //           calendarData: mappedData,
  //           properties,
  //           propertiesCount: totalProperties,
  //         });
  //       } else if (type === 'error') {
  //         worker.terminate();
  //         reject(new Error(error));
  //       }
  //     };

  //     worker.onerror = (error) => {
  //       worker.terminate();
  //       reject(new Error(error.message));
  //     };
  //   });
  // };


  const fetchCalendarDataPage = ({
    page,
    startDate,
    endDate,
    city,
    importantOrgId,
    limit,
  }: {
    page: number;
    startDate: string;
    endDate: string;
    city: string;
    importantOrgId: string;
    limit: number;
  }): Promise<CalendarDataPage> => {

    if (!startDate || !endDate) {
      const [monthName, year] = currentMonth.split(' ');
      const parsedYear = parseInt(year, 10);
      const parsedMonth = parseInt(moment().month(monthName).format('M'), 10);
      startDate = new Date(Date.UTC(parsedYear, parsedMonth - 1, 1))
        .toISOString()
        .split('T')[0];
      endDate = new Date(Date.UTC(parsedYear, parsedMonth, 0))
        .toISOString()
        .split('T')[0];
    }
    return new Promise((resolve, reject) => {
      const worker = new Worker(new URL('../api/worker.js', import.meta.url));

      worker.postMessage({
        action: 'fetchPage',
        startDate,
        endDate,
        importantOrgId,
        page,
        limit,
        city,
        latitude,
        longitude,
        guestCount,
        useFreshData: false,
      });

      worker.onmessage = (event) => {
        const { type, data, error } = event.data;

        if (type === 'data') {
          worker.terminate();
          if (!data) {
            reject(new Error('No data available in calendar response.'));
          }

          const { properties = [], reservations = {} } = data;

          const totalProperties = data.totalProperties;

          // const mappedData: ListingType[] = properties.map((property: PropertyList) => {
          //   const calendarItem = reservations[property.id];

          //   return {
          //     calendarPMSList: calendarItem || {},
          //     calendarPropertyId: property.id,
          //     propertyAddress: property.address,
          //     propertyTitle: property.name,
          //     jobOccurrenceList: calendarItem ? calendarItem.jobOccurrenceList : [],
          //   };
          // });

          resolve({
            calendarData: reservations,
            properties: properties,
            propertiesCount: totalProperties,
          });
        } else if (type === 'error') {
          worker.terminate();
          reject(new Error(error));
        }
      };

      worker.onerror = (error) => {
        worker.terminate();
        reject(new Error(error.message));
      };
    });
  };

  const getPagesToQuery = useCallback(() => {
    return pagesToQuery.length === 0 ? [1] : pagesToQuery;
  }, [pagesToQuery]);

  const queries = useQueries(
    totalProperties !== null ?
      getPagesToQuery().map((page) => ({
        queryKey: [
          'calendarData',
          currentMonth,
          selectedRange.startDate,
          selectedRange.endDate,
          city,
          latitude,
          longitude,
          guestCount,
          page,
        ],
        queryFn: () =>
          fetchCalendarDataPage({
            page,
            startDate: selectedRange.startDate,
            endDate: selectedRange.endDate,
            city,
            importantOrgId,
            limit,
          }),
        enabled: !!importantOrgId,
      }))
      : [
        {
          queryKey: ['calendarData'],
          enabled: false,
        }
      ]
  );

  const allCalendarData: AllCalendarData = queries.reduce((acc, q) => {
    if (q.data?.calendarData) {
      Object.assign(acc, q.data.calendarData);
    }
    return acc;
  }, {} as AllCalendarData);

  // console.log("all calendat data", allCalendarData); 

  const allProperties = queries
    .filter((q) => q.data)
    .flatMap((q) => q.data?.properties ?? []);

  const isLoading = queries[0]?.isLoading;

  const isFetching = queries.some((q, index) => index > 0 && q.isFetching);

  const updateProperties = (checkInDate: string, checkOutDate: string, city: string, latitude: number | null, longitude: number | null, guestCount: number | null) => {
    setIsFiltered(true);
    setSelectedRange({ startDate: checkInDate, endDate: checkOutDate });
    setCity(city);
    setLatitude(latitude);
    setLongitude(longitude);
    setGuestCount(guestCount);
    setSpecificDate(checkInDate);
    setNeedToScrollToSpecificDate(true);
  };

  const resetFilter = () => {
    setNeedToScrollToFirstDate(true);
    setIsFiltered(false);
    setSelectedRange({ startDate: '', endDate: '' });
    setCity('');
    setLatitude(null);
    setLongitude(null);
    setGuestCount(null);
  };

  const closeBookingSidebar = useCallback(() => {
    setSelectedBooking(null);
    setSelectedProperty(undefined);
    setIsBookingSidebarOpen(false);
    setSelectedPage(0);
  }, []);

  const closeJobSidebar = useCallback(() => {
    setIsJobSidebarOpen(false);
    setSelectedDate(moment().format("MMMM YYYY"));
    setSelectedProperty(undefined);
    setSelectedPage(0);
  }, []);

  const closeReservationSidebar = useCallback(() => {
    setIsReservationSidebarOpen(false);
    setSelectedDate(moment().format("MMMM YYYY"));
    setSelectedProperty(undefined);
    setSelectedPage(0);
  }, []);

  const closeJobDetailSidebar = useCallback(() => {
    setIsJobDetailSidebarOpen(false);
    setSelectedJob(null);
    setSelectedProperty(undefined);
  }, []);

  const closeMoreDetailSidebar = useCallback(() => {
    setIsMoreDetailSidebarOpen(false);
    setCurrentJobs([]);
    setSelectedProperty(undefined);
  }, []);

  const closeAllSidebars = useCallback(() => {
    // console.log("this triggered");
    closeJobSidebar();
    closeReservationSidebar();
    closeBookingSidebar();
    closeJobDetailSidebar();
    closeMoreDetailSidebar();
  }, [
    closeJobSidebar,
    closeReservationSidebar,
    closeBookingSidebar,
    closeJobDetailSidebar,
    closeMoreDetailSidebar,
  ]);

  const openBookingSidebar = useCallback(
    (property: PropertyList | undefined, booking: CalendarPMS, pageNumber: number) => {
      closeAllSidebars();
      setSelectedBooking(booking);
      setSelectedProperty(property);
      setSelectedPage(pageNumber);
      setIsBookingSidebarOpen(true);
    },
    [closeAllSidebars]
  );

  const openJobSidebar = useCallback(
    (property: PropertyList | undefined, date: string, pageNumber: number) => {
      closeAllSidebars();
      setSelectedDate(date);
      setSelectedProperty(property);
      setSelectedPage(pageNumber);
      setIsJobSidebarOpen(true);
    },
    [closeAllSidebars]
  );

  const openReservationSidebar = useCallback(
    (property: PropertyList | undefined, date: string, pageNumber: number) => {
      closeAllSidebars();
      setSelectedDate(date);
      setSelectedProperty(property);
      setSelectedPage(pageNumber);
      setIsReservationSidebarOpen(true);
    },
    [closeAllSidebars]
  );

  const openJobDetailSidebar = useCallback(
    (job: Job, property: PropertyList | undefined) => {
      closeAllSidebars();
      setSelectedJob(job);
      setSelectedProperty(property);
      setIsJobDetailSidebarOpen(true);
    },
    [closeAllSidebars]
  );

  const openMoreDetailSidebar = useCallback(
    (jobList: Job[], property: PropertyList | undefined) => {
      closeAllSidebars();
      setCurrentJobs(jobList);
      setSelectedProperty(property);
      setIsMoreDetailSidebarOpen(true);
    },
    [closeAllSidebars]
  );

  const queryClient = useQueryClient();

  const refetchPage = useCallback((page: number) => {
    const queryKey = [
      'calendarData',
      currentMonth,
      selectedRange.startDate,
      selectedRange.endDate,
      city,
      latitude,
      longitude,
      guestCount,
      page,
    ];
    queryClient.invalidateQueries(queryKey);
  }, [city, currentMonth, guestCount, latitude, longitude, queryClient, selectedRange]);

  const handleReservationCreated = async (date: string, newReservationId: string | null, pageNumber: number) => {
    setNewlyAddedReservationId('');

    const newDateMoment = moment(date, 'YYYY-MM-DD');
    const monthName = newDateMoment.format('MMMM');
    const year = newDateMoment.format('YYYY');

    const monthIndex = newDateMoment.month();
    setDates(generateDatesForMonth(Number(year), monthIndex));
    setCurrentMonth(`${monthName} ${year}`);

    await refetchPage(pageNumber);

    setNewlyAddedReservationId(newReservationId);
  };

  const handleJobCreated = async (date: string, newJobId: string | null, pageNumber: number) => {
    setNewlyAddedJobId('');
    const newDateMoment = moment(date, 'YYYY-MM-DD');
    const monthName = newDateMoment.format('MMMM');
    const year = newDateMoment.format('YYYY');

    const monthIndex = newDateMoment.month();
    setDates(generateDatesForMonth(Number(year), monthIndex));
    setCurrentMonth(`${monthName} ${year}`);

    // await queryClient.invalidateQueries(['calendarData', `${monthName} ${year}`]);

    await refetchPage(pageNumber);

    setNewlyAddedJobId(newJobId);
  };

  const handleReservationCancelled = async (date: string | undefined, reservationId: string | null, pageNumber: number) => {
    const newDateMoment = moment(date, 'YYYY-MM-DD');
    const monthName = newDateMoment.format('MMMM');
    const year = newDateMoment.format('YYYY');

    const monthIndex = newDateMoment.month();
    setDates(generateDatesForMonth(Number(year), monthIndex));
    setCurrentMonth(`${monthName} ${year}`);

    await refetchPage(pageNumber);

    setSpecificDate(date);
    setNeedToScrollToSpecificDate(true);
  };

  const displayedProperties = React.useMemo(() => {
    if (!filteredSearchTerm) return allProperties;
    return allProperties.filter((property) =>
      property?.name?.toLowerCase().includes(filteredSearchTerm.toLowerCase()) ||
      property?.address?.toLowerCase().includes(filteredSearchTerm.toLowerCase())
    );
  }, [filteredSearchTerm, allProperties]);


  const listings = Object.fromEntries(
    Object.entries(allCalendarData).filter(([propertyId]) =>
      displayedProperties.some((fp) => fp.id === propertyId)
    )
  );

  return (
    <>
      <div className="multicalendar-header-container">
        <div className="search-listings">
          <div className="search-wrapper">
            <input
              type="text"
              placeholder="Search Listings"
              value={searchTerm}
              onChange={handleSearch}
              className="search-input"
            />
          </div>
        </div>
        <div className="calendar-header">
          <div className="date-filter-container">
            <div className="date-filter">
              <button className="navi-btn" onClick={() => handleMonthChange('prev')} disabled={loadingSecond} style={{
                cursor: loadingSecond ? 'progress' : 'pointer'
              }}>
                <img src={leftIcon} alt="left-button" className='left-icon' />
              </button>
              <CustomMonthDropdown
                options={monthOptions}
                selectedOption={currentMonth}
                onSelect={handleMonthSelectChange}
              />
              <button className="navi-btn" onClick={() => handleMonthChange('next')} disabled={loadingSecond} style={{
                cursor: loadingSecond ? 'progress' : 'pointer'
              }}>
                <img src={rightIcon} alt="right-button" className='right-icon' />
              </button>
            </div>
            <div>
              <button className="secondary-btn ml-24" onClick={handleTodayClick}>
                Today
              </button>
            </div>
          </div>
          <div className="another-button">
            {isFiltered && (
              <button className="secondary-btn mr-8" onClick={resetFilter}>
                Reset
              </button>
            )}
            <button className="primary-btn" onClick={toggleModal}>
              Availability
            </button>
          </div>
        </div>
      </div>

      <Calendar
        ref={calendarRef}
        dates={dates}
        allPropertyCount={totalProperties}
        properties={displayedProperties}
        listings={listings}
        onBookingClick={openBookingSidebar}
        onJobClick={openJobSidebar}
        onReservationClick={openReservationSidebar}
        onJobDetailClick={openJobDetailSidebar}
        onMoreJobDetailClick={openMoreDetailSidebar}
        loading={isLoading || isSearchLoading || loadingSecond}
        isFetchingMore={isFetching}
        selectedRange={selectedRange}
        newlyAddedReservationId={newlyAddedReservationId}
        newlyAddedJobId={newlyAddedJobId}
      />


      {isModalOpen && <FindAvailabilityModal currentMonth={currentMonth} onClose={toggleModal} updateProperties={updateProperties} />}

      {/* Sidebars */}
      {isBookingSidebarOpen && (
        <BookingSidebar
          reservationId={selectedBooking?.reservationPMS?.id}
          guestName={selectedBooking?.reservationPMS?.guestFullName}
          isManualBlock={selectedBooking?.reservationPMS?.otaName === 'Manual Block' && selectedBooking?.reservationPMS?.customerFirstName != 'Manual Block Pair Booking'}
          isPairBooking={selectedBooking?.reservationPMS?.customerFirstName === 'Manual Block Pair Booking'}
          isAdvanceNotice={selectedBooking?.reservationPMS?.otaName === 'Advance Notice'}
          isAfterBlock={selectedBooking?.reservationPMS?.otaName === 'After Block'}
          isBeforeBlock={selectedBooking?.reservationPMS?.otaName === 'Before Block'}
          isRollingWindow={selectedBooking?.reservationPMS?.otaName === 'Rolling Window' || selectedBooking?.reservationPMS?.otaName === 'Rolling window'}
          arrivalDate={selectedBooking?.reservationPMS?.arrivalDate}
          departureDate={selectedBooking?.reservationPMS?.departureDate}
          defaultJobId={selectedBooking?.jobList?.[0]?.bubbleTaskId}
          onClose={closeBookingSidebar}
          sideBar={isBookingSidebarOpen}
          propertyPassed={selectedProperty}
          selectedPage={selectedPage}
          onReservationCancel={handleReservationCancelled}
        />
      )}

      {isJobSidebarOpen && (
        <JobSidebar
          selectedDate={selectedDate}
          onClose={closeJobSidebar}
          sideBar={isJobSidebarOpen}
          property={selectedProperty}
          selectedPage={selectedPage}
          onJobCreated={handleJobCreated}
        />
      )}

      {isReservationSidebarOpen && (
        <ReservationSidebar
          onClose={closeReservationSidebar}
          sideBar={isReservationSidebarOpen}
          selectedDate={selectedDate}
          property={selectedProperty}
          selectedPage={selectedPage}
          onReservationCreated={handleReservationCreated}
        />
      )}

      {isJobDetailSidebarOpen && selectedJob && (
        <JobDetailSidebar
          onClose={closeJobDetailSidebar}
          sideBar={isJobDetailSidebarOpen}
          jobDetail={selectedJob}
          property={selectedProperty}
        />
      )}

      {
        isMoreDetailSidebarOpen && currentJobs && (
          <MoreJobDetailSidebar
            onClose={closeMoreDetailSidebar}
            sideBar={isMoreDetailSidebarOpen}
            jobs={currentJobs}
            property={selectedProperty}
          />
        )
      }

    </>
  );
};

export default MultiViewCalendarApp;

// import debounce from 'lodash.debounce';
// import moment from 'moment';
// import React, { useCallback, useContext, useEffect, useRef, useState, useTransition } from 'react';
// import { generateDatesForMonth } from '../utils/functionUtils';
// import Calendar from './Calendar';
// import FindAvailabilityModal from './modals/FindAvailabilityModal';
// import {
//   CalendarPMS,
//   Listing as ListingType,
//   PropertyList,
//   Job
// } from '../api/type';

// import { useInfiniteQuery, useQueryClient, QueryFunctionContext } from 'react-query';

// import { ImportantOrgContext } from '../App';
// import leftIcon from './assets/arrow-left.svg';
// import rightIcon from './assets/arrow-right.svg';
// import CustomMonthDropdown from './dropdown/CustomMonthDropdown';
// import BookingSidebar from './sidebars/BookingSidebar';
// import JobDetailSidebar from './sidebars/JobDetailSidebar';
// import JobSidebar from './sidebars/JobSidebar';
// import MoreJobDetailSidebar from './sidebars/MoreJobDetailSidebar';
// import ReservationSidebar from './sidebars/ReservationSidebar';
// import { fetchCalendarDataVFour } from '../api/request';

// interface CalendarRef {
//   scrollToCurrentDate: () => void;
//   scrollToFirstDate: () => void;
//   scrollToSpecificDate: (targetDate: string) => void;
//   scrollToSpecificProperty: (propertyId: string) => void;
// }

// interface DateRange {
//   startDate: string;
//   endDate: string;
// }

// const generateMonthOptions = () => {
//   const startMonth = moment('2024-01-01');
//   const endMonth = moment('2025-12-31');
//   const months = [];
//   const current = startMonth.clone();

//   while (current.isSameOrBefore(endMonth)) {
//     months.push(current.format('MMMM YYYY'));
//     current.add(1, 'month');
//   }

//   return months;
// };

// interface CalendarDataPage {
//   calendarData: ListingType[];
//   properties: PropertyList[];
//   nextPage: number;
//   hasMore: boolean;
//   propertiesCount: number | undefined;
// }

// // type CalendarQueryKey = [string, string, string | undefined, string | undefined, string | undefined];
// type CalendarQueryKey = [
//   string,
//   string,
//   string | undefined,
//   string | undefined,
//   string | undefined,
//   number | null,
//   number | null,
//   number | null
// ];


// const CalendarApp: React.FC = () => {
//   const importantOrgId = useContext(ImportantOrgContext);

//   const [totalProperties, setTotalProperties] = useState<number>();
//   const [filteredProperties, setFilteredProperties] = useState<PropertyList[]>([]);
//   const [searchTerm, setSearchTerm] = useState<string>('');
//   const [filteredSearchTerm, setFilteredSearchTerm] = useState<string>('');
//   const [dates, setDates] = useState<string[]>([]);
//   const [currentMonth, setCurrentMonth] = useState<string>(moment().format('MMMM YYYY'));
//   const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
//   const [loadingSecond, setLoadingSecond] = useState<boolean>(false);

//   const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);
//   const [_loadedPages, setLoadedPages] = useState<number[]>([]);
//   const loadedPagesRef = useRef<number[]>([]);
//   const [limit] = useState<number>(10);
//   const [selectedRange, setSelectedRange] = useState<DateRange>({ startDate: '', endDate: '' });
//   const [city, setCity] = useState<string>('');
//   const [latitude, setLatitude] = useState<number | null>(null);
//   const [longitude, setLongitude] = useState<number | null>(null);
//   const [guestCount, setGuestCount] = useState<number | null>(null);

//   const MAX_CONCURRENT_REQUESTS = 1;

//   const [selectedDate, setSelectedDate] = useState<string>(moment().format("MMMM YYYY"));
//   const [isFiltered, setIsFiltered] = useState<boolean>(false);

//   const [isBookingSidebarOpen, setIsBookingSidebarOpen] = useState<boolean>(false);
//   const [selectedBooking, setSelectedBooking] = useState<CalendarPMS | null>(null);
//   const [selectedProperty, setSelectedProperty] = useState<PropertyList | undefined>(undefined);

//   const [isJobSidebarOpen, setIsJobSidebarOpen] = useState<boolean>(false);
//   const [selectedJob, setSelectedJob] = useState<Job | null>(null);
//   const [selectedPage, setSelectedPage] = useState<number>(0);
//   const [currentJobs, setCurrentJobs] = useState<Job[]>([]);

//   const [isReservationSidebarOpen, setIsReservationSidebarOpen] = useState<boolean>(false);
//   const [isJobDetailSidebarOpen, setIsJobDetailSidebarOpen] = useState<boolean>(false);
//   const [isMoreDetailSidebarOpen, setIsMoreDetailSidebarOpen] = useState(false);

//   const [needToScrollToCurrentDate, setNeedToScrollToCurrentDate] = useState<boolean>(false);
//   const [needToScrollToFirstDate, setNeedToScrollToFirstDate] = useState<boolean>(false);
//   const [needToScrollToSpecificDate, setNeedToScrollToSpecificDate] = useState<boolean>(false);
//   const [needToScrollToSpecificProperty, setNeedToScrollToSpecificProperty] = useState<boolean>(false);
//   const [specificDate, setSpecificDate] = useState<string | undefined>();
//   const [specificPropertyId, setSpecificPropertyId] = useState<string | undefined>();
//   const [errorMessage, setErrorMessage] = useState<string | null>(null);
//   const [isRetrying, setIsRetrying] = useState<boolean>(false);
//   const [newlyAddedReservationId, setNewlyAddedReservationId] = useState<string | null>(null);
//   const [newlyAddedJobId, setNewlyAddedJobId] = useState<string | null>(null);
//   const [monthOptions] = useState<string[]>(generateMonthOptions());

//   const [itemsToRender, setItemsToRender] = useState(10);

//   const [isPending, startTransition] = useTransition();
//   const [isLoadingItem, startItemTransition] = useTransition();

//   const hasScrolledToToday = useRef(false);

//   const closeBookingSidebar = useCallback(() => {
//     setSelectedBooking(null);
//     setSelectedProperty(undefined);
//     setIsBookingSidebarOpen(false);
//   }, []);

//   const closeJobSidebar = useCallback(() => {
//     setIsJobSidebarOpen(false);
//     setSelectedDate(moment().format("MMMM YYYY"));
//     setSelectedProperty(undefined);
//   }, []);

//   const closeReservationSidebar = useCallback(() => {
//     setIsReservationSidebarOpen(false);
//     setSelectedDate(moment().format("MMMM YYYY"));
//     setSelectedProperty(undefined);
//     setSelectedPage(0);
//   }, []);

//   const closeJobDetailSidebar = useCallback(() => {
//     setIsJobDetailSidebarOpen(false);
//     setSelectedJob(null);
//     setSelectedProperty(undefined);
//   }, []);

//   const closeMoreDetailSidebar = useCallback(() => {
//     setIsMoreDetailSidebarOpen(false);
//     setCurrentJobs([]);
//     setSelectedProperty(undefined);
//   }, []);

//   const closeAllSidebars = useCallback(() => {
//     console.log("this triggered");
//     closeJobSidebar();
//     closeReservationSidebar();
//     closeBookingSidebar();
//     closeJobDetailSidebar();
//     closeMoreDetailSidebar();
//   }, [
//     closeJobSidebar,
//     closeReservationSidebar,
//     closeBookingSidebar,
//     closeJobDetailSidebar,
//     closeMoreDetailSidebar,
//   ]);


//   const openBookingSidebar = useCallback(
//     (property: PropertyList | undefined, booking: CalendarPMS) => {
//       closeAllSidebars();
//       setSelectedBooking(booking);
//       setSelectedProperty(property);
//       setIsBookingSidebarOpen(true);
//     },
//     [closeAllSidebars]
//   );

//   const openJobSidebar = useCallback(
//     (property: PropertyList | undefined, date: string) => {
//       closeAllSidebars();
//       setSelectedDate(date);
//       setSelectedProperty(property);
//       setIsJobSidebarOpen(true);
//     },
//     [closeAllSidebars]
//   );

//   const openReservationSidebar = useCallback(
//     (property: PropertyList | undefined, date: string, pageNumber: number) => {
//       closeAllSidebars();
//       setSelectedDate(date);
//       setSelectedProperty(property);
//       setSelectedPage(pageNumber);
//       setIsReservationSidebarOpen(true);
//     },
//     [closeAllSidebars]
//   );

//   const openJobDetailSidebar = useCallback(
//     (job: Job, property: PropertyList | undefined) => {
//       closeAllSidebars();
//       setSelectedJob(job);
//       setSelectedProperty(property);
//       setIsJobDetailSidebarOpen(true);
//     },
//     [closeAllSidebars]
//   );

//   const openMoreDetailSidebar = useCallback(
//     (jobList: Job[], property: PropertyList | undefined) => {
//       closeAllSidebars();
//       setCurrentJobs(jobList);
//       setSelectedProperty(property);
//       setIsMoreDetailSidebarOpen(true);
//     },
//     [closeAllSidebars]
//   );

//   const calendarRef = useRef<CalendarRef>(null);

//   const debouncedSetFilteredSearchTerm = useCallback(
//     debounce((term: string) => {
//       startTransition(() => {
//         setFilteredSearchTerm(term);
//       });
//     }, 100),
//     []
//   );

//   const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(event.target.value);
//     debouncedSetFilteredSearchTerm(event.target.value);
//   }, [debouncedSetFilteredSearchTerm]);

//   const scrollToToday = useCallback(() => {
//     if (calendarRef.current) {
//       calendarRef.current.scrollToCurrentDate();
//     }
//   }, []);

//   const handleMonthChange = useCallback(
//     (direction: 'prev' | 'next') => {
//       hasScrolledToToday.current = false;
//       const newMonth = moment(currentMonth, 'MMMM YYYY')
//         .add(direction === 'next' ? 1 : -1, 'month')
//         .format('MMMM YYYY');
//       const [monthName, year] = newMonth.split(' ');

//       setCurrentMonth(newMonth);
//       setDates(generateDatesForMonth(Number(year), moment().month(monthName).month()));

//       if (moment().format('MMMM YYYY') !== newMonth) {
//         setNeedToScrollToFirstDate(true);
//       }
//     },
//     [currentMonth]
//   );

//   const handleMonthSelectChange = useCallback(
//     (selectedMonth: string) => {
//       setLoadingSecond(true);
//       setCurrentMonth(selectedMonth);
//       const [monthName, year] = selectedMonth.split(' ');
//       const monthIndex = moment().month(monthName).month();
//       setDates(generateDatesForMonth(Number(year), monthIndex));

//       queryClient.prefetchQuery(['calendarData', `${monthName} ${year}`]).then(() => {
//         setLoadingSecond(false);
//       });

//       if (moment().format('MMMM YYYY') !== selectedMonth) {
//         setNeedToScrollToFirstDate(true);
//       } else {
//         scrollToToday();
//       }

//       queryClient.prefetchQuery(['calendarData', `${monthName} ${year}`]).then(() => {
//         setLoadingSecond(false);
//       });
//     },
//     [scrollToToday]
//   );

//   const handleTodayClick = useCallback(() => {
//     hasScrolledToToday.current = false;
//     const todayMonth = moment().format('MMMM YYYY');
//     if (currentMonth !== todayMonth) {

//       setLoadingSecond(true);

//       const currentYear = moment().year();
//       const currentMonthIndex = moment().month();
//       const monthName = moment().format('MMMM');
//       const year = moment().format('YYYY');
//       setCurrentMonth(todayMonth);
//       setDates(generateDatesForMonth(currentYear, currentMonthIndex));

//       setTimeout(() => {
//         setLoadingSecond(false);
//         scrollToToday();
//       }, 0);
//     } else {
//       scrollToToday();
//     }
//   }, [currentMonth, scrollToToday]);

//   const toggleModal = useCallback(() => {
//     setIsModalOpen(!isModalOpen);
//   }, [isModalOpen]);

//   const fetchCalendarDataPage = ({ pageParam = 1, queryKey }: QueryFunctionContext<CalendarQueryKey>): Promise<CalendarDataPage> => {
//     return new Promise((resolve, reject) => {
//       const worker = new Worker(new URL('../api/worker.js', import.meta.url));

//       const [_, currentMonthKey, startDateKey, endDateKey, cityKey, latitudeKey, longitudeKey, guestCountKey] = queryKey;

//       let startDate = startDateKey;
//       let endDate = endDateKey;
//       const city = cityKey || '';

//       if (!startDate || !endDate) {
//         const [monthName, year] = currentMonthKey.split(' ');
//         const parsedYear = parseInt(year, 10);
//         const parsedMonth = parseInt(moment().month(monthName).format("M"), 10);

//         startDate = new Date(Date.UTC(parsedYear, parsedMonth - 1, 1))
//           .toISOString()
//           .split("T")[0];

//         endDate = new Date(Date.UTC(parsedYear, parsedMonth, 0))
//           .toISOString()
//           .split("T")[0];
//       }

//       worker.postMessage({
//         action: 'fetchPage',
//         startDate,
//         endDate,
//         importantOrgId,
//         page: pageParam,
//         limit,
//         city,
//         latitude,
//         longitude,
//         guestCount,
//         useFreshData: false,
//       });

//       worker.onmessage = (event) => {
//         const { type, data, error } = event.data;

//         if (type === 'data') {
//           worker.terminate();
//           if (!data) {
//             reject(new Error('No data available in calendar response.'));
//           }

//           const { properties = [], reservations = [] } = data;
//           const totalProperties = data.totalProperties;

//           const mappedData: ListingType[] = properties.map((property: PropertyList) => {
//             const calendarItem = reservations.find((cd: ListingType) => cd.calendarPropertyId === property.id);
//             return {
//               calendarPMSList: calendarItem ? calendarItem.calendarPMSList : [],
//               calendarPropertyId: property.id,
//               propertyAddress: property.address,
//               propertyTitle: property.name,
//               jobOccurrenceList: calendarItem ? calendarItem.jobOccurrenceList : [],
//             };
//           });

//           const hasMore = properties.length === limit;

//           resolve({
//             calendarData: mappedData,
//             properties: properties,
//             nextPage: pageParam + 1,
//             hasMore: hasMore,
//             propertiesCount: totalProperties,
//           });
//         } else if (type === 'error') {
//           worker.terminate();
//           reject(new Error(error));
//         }
//       };

//       worker.onerror = (error) => {
//         worker.terminate();
//         reject(new Error(error.message));
//       };
//     });
//   };

//   const queryKey: CalendarQueryKey = ['calendarData', currentMonth, selectedRange.startDate, selectedRange.endDate, city, latitude, longitude, guestCount];
//   console.log('Query Key For This Month!!!:', queryKey);

//   const {
//     data: queryData,
//     error,
//     fetchNextPage,
//     hasNextPage,
//     isFetching,
//     isFetchingNextPage,
//     isLoading,
//     status,
//     refetch,
//   } = useInfiniteQuery<CalendarDataPage, Error, CalendarDataPage, CalendarQueryKey>(
//     queryKey,
//     fetchCalendarDataPage,
//     {
//       getNextPageParam: (lastPage) =>
//         lastPage.hasMore && selectedPage === 0 ? lastPage.nextPage : undefined,
//       enabled: selectedPage === 0,
//     }
//   );

//   const allCalendarData = React.useMemo(() => {
//     return queryData?.pages ? queryData.pages.flatMap((page) => page.calendarData) : [];
//   }, [queryData]);

//   const allProperties = React.useMemo(() => {
//     return queryData?.pages ? queryData.pages.flatMap((page) => page.properties) : [];
//   }, [queryData]);

//   const allPropertyCount = queryData?.pages?.[0]?.propertiesCount ?? 0;


//   const loading = isLoading;

//   useEffect(() => {
//     if (needToScrollToFirstDate && calendarRef.current) {
//       calendarRef.current.scrollToFirstDate();
//       setNeedToScrollToFirstDate(false);
//     }
//   }, [needToScrollToFirstDate]);

//   useEffect(() => {
//     if (needToScrollToCurrentDate && calendarRef.current) {
//       calendarRef.current.scrollToCurrentDate();
//       setNeedToScrollToCurrentDate(false);
//     }
//   }, [needToScrollToCurrentDate]);

//   useEffect(() => {
//     if (specificDate && needToScrollToSpecificDate && calendarRef.current) {
//       calendarRef.current.scrollToSpecificDate(specificDate);
//       setNeedToScrollToSpecificDate(false);
//     }
//   }, [needToScrollToSpecificDate, specificDate]);

//   useEffect(() => {
//     if (specificPropertyId && needToScrollToSpecificProperty && calendarRef.current) {
//       calendarRef.current.scrollToSpecificProperty(specificPropertyId);
//       setNeedToScrollToSpecificProperty(false);
//     }
//   }, [needToScrollToSpecificProperty, specificPropertyId]);

//   useEffect(() => {
//     if (!isRetrying && errorMessage) setErrorMessage(null);
//   }, [isRetrying, errorMessage]);

//   useEffect(() => {
//     const [monthName, year] = currentMonth.split(' ');
//     const monthIndex = moment().month(monthName).month();
//     setDates(generateDatesForMonth(Number(year), monthIndex));
//   }, [currentMonth]);

//   useEffect(() => {
//     const fetchAllPages = async () => {
//       let localHasNextPage = hasNextPage;
//       while (localHasNextPage) {
//         const result = await fetchNextPage();
//         localHasNextPage = result.hasNextPage;
//       }
//     };

//     if (hasNextPage) {
//       fetchAllPages();
//     }
//   }, [hasNextPage, fetchNextPage]);

//   const updateProperties = (checkInDate: string, checkOutDate: string, city: string, latitude: number | null, longitude: number | null, guestCount: number | null) => {
//     setIsFiltered(true);
//     setSelectedRange({ startDate: checkInDate, endDate: checkOutDate });
//     setCity(city);
//     setLatitude(latitude);
//     setLongitude(longitude);
//     setGuestCount(guestCount);
//     setSpecificDate(checkOutDate);
//     setNeedToScrollToFirstDate(true);
//     refetch();
//   };

//   const resetFilter = () => {
//     setNeedToScrollToFirstDate(true);
//     setIsFiltered(false);
//     setSelectedRange({ startDate: '', endDate: '' });
//     setCity('');
//     setLatitude(null);
//     setLongitude(null);
//     setGuestCount(null);
//   };

//   const queryClient = useQueryClient();

//   const handleReservationCreated = async (date: string, newReservationId: string | null, pageNumber: number) => {
//     setSpecificDate('');
//     setNewlyAddedReservationId('');

//     const newDateMoment = moment(date, 'YYYY-MM-DD');
//     const monthName = newDateMoment.format('MMMM');
//     const year = newDateMoment.format('YYYY');

//     const monthIndex = newDateMoment.month();
//     setDates(generateDatesForMonth(Number(year), monthIndex));
//     setCurrentMonth(`${monthName} ${year}`);

//     await queryClient.invalidateQueries(['calendarData', `${monthName} ${year}`]);

//     setNewlyAddedReservationId(newReservationId);
//   };

//   const handleCheckCache = () => {
//     const cachedData = queryClient.getQueryData(['calendarData', 'December 2024', '', '', '', null, null, null]);
//     console.log('Cached Data:', cachedData);
//   };


//   const handleJobCreated = async (date: string, newJobId: string | null) => {
//     setNewlyAddedJobId('');
//     const newDateMoment = moment(date, 'YYYY-MM-DD');
//     const monthName = newDateMoment.format('MMMM');
//     const year = newDateMoment.format('YYYY');

//     const monthIndex = newDateMoment.month();
//     setDates(generateDatesForMonth(Number(year), monthIndex));
//     setCurrentMonth(`${monthName} ${year}`);

//     await queryClient.invalidateQueries(['calendarData', `${monthName} ${year}`]);

//     setNewlyAddedJobId(newJobId);
//   };

//   const handleReservationCancelled = async (date: string | undefined, reservationId: string | null) => {
//     const newDateMoment = moment(date, 'YYYY-MM-DD');
//     const monthName = newDateMoment.format('MMMM');
//     const year = newDateMoment.format('YYYY');

//     const monthIndex = newDateMoment.month();
//     setDates(generateDatesForMonth(Number(year), monthIndex));
//     setCurrentMonth(`${monthName} ${year}`);

//     await queryClient.invalidateQueries(['calendarData', `${monthName} ${year}`]);

//     setSpecificDate(date);
//     setNeedToScrollToSpecificDate(true);
//   };

//   const displayedProperties = React.useMemo(() => {
//     if (!filteredSearchTerm) return allProperties;
//     return allProperties.filter((property) =>
//       property?.name?.toLowerCase().includes(filteredSearchTerm.toLowerCase()) ||
//       property?.address?.toLowerCase().includes(filteredSearchTerm.toLowerCase())
//     );
//   }, [filteredSearchTerm, allProperties]);

//   return (
//     <>
//       <div className="multicalendar-header-container">
//         <div className="search-listings">
//           <div className="search-wrapper">
//             <input
//               type="text"
//               placeholder="Search Listings"
//               value={searchTerm}
//               onChange={handleSearch}
//               className="search-input"
//             />
//           </div>
//         </div>
//         <div className="calendar-header">
//           <div className="date-filter-container">
//             <div className="date-filter">
//               <button className="navi-btn" onClick={() => handleMonthChange('prev')} disabled={loadingSecond || isFetchingNextPage} style={{
//                 cursor: isFetchingNextPage || loadingSecond ? 'progress' : 'pointer'
//               }}>
//                 <img src={leftIcon} alt="left-button" className='left-icon' />
//               </button>
//               <CustomMonthDropdown
//                 options={monthOptions}
//                 selectedOption={currentMonth}
//                 onSelect={handleMonthSelectChange}
//                 disabled={loading || isFetchingNextPage}
//               />
//               <button className="navi-btn" onClick={() => handleMonthChange('next')} disabled={loadingSecond} style={{
//                 cursor: isFetchingNextPage || loadingSecond ? 'progress' : 'pointer'
//               }}>
//                 <img src={rightIcon} alt="right-button" className='right-icon' />
//               </button>
//             </div>
//             <div>
//               <button className="secondary-btn ml-24" onClick={handleTodayClick}>
//                 Today
//               </button>
//             </div>
//           </div>
//           <div className="another-button">
//             {isFiltered && (
//               <button className="secondary-btn mr-8" onClick={resetFilter}>
//                 Reset
//               </button>
//             )}
//             <button className="primary-btn" onClick={handleCheckCache}>
//               Availability
//             </button>
//           </div>
//         </div>
//       </div>

//       <Calendar
//         ref={calendarRef}
//         dates={dates}
//         allPropertyCount={allPropertyCount}
//         properties={displayedProperties}
//         listings={allCalendarData.filter((cd) =>
//           displayedProperties.some((fp) => fp.id === cd.calendarPropertyId)
//         )}
//         onBookingClick={openBookingSidebar}
//         onJobClick={openJobSidebar}
//         onReservationClick={openReservationSidebar}
//         onJobDetailClick={openJobDetailSidebar}
//         onMoreJobDetailClick={openMoreDetailSidebar}
//         loading={loading || isPending || loadingSecond}
//         isFetchingMore={isFetchingNextPage || isLoadingItem}
//         newlyAddedReservationId={newlyAddedReservationId}
//         newlyAddedJobId={newlyAddedJobId}
//       />

//       {isModalOpen && <FindAvailabilityModal onClose={toggleModal} updateProperties={updateProperties} />}

//       {/* Sidebars */}
//       {isBookingSidebarOpen && (
//         <BookingSidebar
//           reservationId={selectedBooking?.reservationPMS?.id}
//           guestName={selectedBooking?.reservationPMS?.guestFullName}
//           isManualBlock={selectedBooking?.reservationPMS?.otaName === 'Manual Block'}
//           isAdvanceNotice={selectedBooking?.reservationPMS?.otaName === 'Advance Notice'}
//           isAfterBlock={selectedBooking?.reservationPMS?.otaName === 'After Block'}
//           isBeforeBlock={selectedBooking?.reservationPMS?.otaName === 'Before Block'}
//           isRollingWindow={selectedBooking?.reservationPMS?.otaName === 'Rolling Window' || selectedBooking?.reservationPMS?.otaName === 'Rolling window'}
//           arrivalDate={selectedBooking?.reservationPMS?.arrivalDate}
//           departureDate={selectedBooking?.reservationPMS?.departureDate}
//           defaultJobId={selectedBooking?.jobList?.[0]?.bubbleTaskId}
//           onClose={closeBookingSidebar}
//           sideBar={isBookingSidebarOpen}
//           propertyPassed={selectedProperty}
//           onReservationCancel={handleReservationCancelled}
//         />
//       )}

//       {isJobSidebarOpen && (
//         <JobSidebar
//           selectedDate={selectedDate}
//           onClose={closeJobSidebar}
//           sideBar={isJobSidebarOpen}
//           property={selectedProperty}
//           onJobCreated={handleJobCreated}
//         />
//       )}

//       {isReservationSidebarOpen && (
//         <ReservationSidebar
//           onClose={closeReservationSidebar}
//           sideBar={isReservationSidebarOpen}
//           selectedDate={selectedDate}
//           property={selectedProperty}
//           selectedPage={selectedPage}
//           onReservationCreated={handleReservationCreated}
//         />
//       )}

//       {isJobDetailSidebarOpen && selectedJob && (
//         <JobDetailSidebar
//           onClose={closeJobDetailSidebar}
//           sideBar={isJobDetailSidebarOpen}
//           jobDetail={selectedJob}
//           property={selectedProperty}
//         />
//       )}

//       {
//         isMoreDetailSidebarOpen && currentJobs && (
//           <MoreJobDetailSidebar
//             onClose={closeMoreDetailSidebar}
//             sideBar={isMoreDetailSidebarOpen}
//             jobs={currentJobs}
//             property={selectedProperty}
//           />
//         )
//       }

//     </>
//   );
// };

// export default CalendarApp;