import React, { useCallback, useImperativeHandle, useMemo, useRef, forwardRef, useState } from "react";
import { TableVirtuoso } from "react-virtuoso";
import { PropertyList, CalendarPMS } from "../../api/type";
import Moment from "react-moment";
import moment from "moment";
import Listing from "./Listing";
import DayCell from "./DayCell";
import SkeletonRow from "../dropdown/SkeletonRow";
import { getPageNumber } from "../../utils/functionUtils";

interface ListingType {
  calendarPMSList: CalendarPMS[];
  calendarPropertyId: string;
  propertyAddress: string | null;
  propertyTitle: string | null;
}

interface DateEntry {
  date: string;
  jobList: any[];
}


const Calendar = forwardRef(({
  dates,
  allPropertyCount,
  properties,
  listings,
  onBookingClick,
  onJobClick,
  onReservationClick,
  onJobDetailClick,
  onMoreJobDetailClick,
  loading,
  isFetchingMore,
  selectedRange,
  newlyAddedReservationId,
  newlyAddedJobId
}: {
  dates: string[];
  allPropertyCount: number | null;
  properties: PropertyList[];
  listings: any;
  onBookingClick: (property: PropertyList | undefined, booking: CalendarPMS, pageNumber: number) => void;
  onJobClick: (property: PropertyList | undefined, date: string, pageNumber: number) => void;
  onReservationClick: (property: PropertyList | undefined, date: string, pageNumber: number) => void;
  onJobDetailClick: (job: any, property: PropertyList | undefined) => void;
  onMoreJobDetailClick: (jobs: any[], property: PropertyList | undefined) => void;
  loading: boolean;
  isFetchingMore: boolean;
  selectedRange?: {
    startDate: string;
    endDate: string;
  } | null;
  newlyAddedReservationId: string | null;
  newlyAddedJobId: string | null;
}, ref) => {
  const calendarContainerRef = useRef<HTMLDivElement>(null);
  const [isEndReached, setIsEndReached] = useState(false);

  const memoizedOnBookingClick = useCallback(onBookingClick, [onBookingClick]);
  const memoizedOnJobClick = useCallback(onJobClick, [onJobClick]);
  const memoizedOnReservationClick = useCallback(onReservationClick, [onReservationClick]);
  const memoizedOnJobDetailClick = useCallback(onJobDetailClick, [onJobDetailClick]);
  const memoizedOnMoreJobDetailClick = useCallback(onMoreJobDetailClick, [onMoreJobDetailClick]);

  useImperativeHandle(ref, () => ({
    scrollToCurrentDate: () => {
      const today = moment().format('YYYY-MM-DD');
      const currentDateIndex = dates.findIndex(date => moment(date).format('YYYY-MM-DD') === today);
      if (calendarContainerRef.current && currentDateIndex !== -1) {
        const dateElement = calendarContainerRef.current.querySelector(`.date-header-cell-${currentDateIndex}`);
        if (dateElement) {
          dateElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
      }
    },

    scrollToFirstDate: () => {
      if (calendarContainerRef.current) {
        const dateElement = calendarContainerRef.current.querySelector(`.date-header-cell-0`);
        if (dateElement) {
          dateElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
        }
      }
    },

    scrollToSpecificDate: (targetDate: string) => {
      const formattedTargetDate = moment(targetDate).format('YYYY-MM-DD');
      const targetDateIndex = dates.findIndex(date => moment(date).format('YYYY-MM-DD') === formattedTargetDate);
      if (calendarContainerRef.current && targetDateIndex !== -1) {
        const dateElement = calendarContainerRef.current.querySelector(`.date-header-cell-${targetDateIndex}`);
        if (dateElement) {
          dateElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
      }
    },

    scrollToSpecificProperty: (propertyId: string) => {
      if (calendarContainerRef.current) {
        const propertyElement = calendarContainerRef.current.querySelector(
          `.property-row-${propertyId}`
        );
        if (propertyElement) {
          propertyElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
      }
    },
  }));

  // console.log("listings", listings);

  const isDateInRange = (date: string) => {
    if (!selectedRange || !selectedRange.startDate || !selectedRange.endDate) return true;
    const momentDate = moment(date, 'YYYY-MM-DD');
    const startDate = moment(selectedRange.startDate, 'YYYY-MM-DD');
    const endDate = moment(selectedRange.endDate, 'YYYY-MM-DD');
    return momentDate.isBetween(startDate, endDate, null, '[]');
  };

  const getJobListForDate = useCallback(
    (listings: Record<string, Record<string, DateEntry>>, propertyId: string, date: string) => {
      const propertyData = listings[propertyId];
      if (!propertyData) return [];

      const dateData = Object.values(propertyData).find(
        (entry: DateEntry) => entry.date === date
      );

      return dateData?.jobList || [];
    },
    []
  );

  return (
    <div className="calendar-table" ref={calendarContainerRef}>
      <TableVirtuoso
        style={{
          height: '89vh'
        }}
        data={loading ? new Array(10).fill(null) : properties}
        endReached={() => {
          setIsEndReached(true);
        }}
        components={{
          Table: ({ style, ...props }) => (
            <table {...props} style={{ ...style, width: "100%" }} />
          ),
        }}
        fixedFooterContent={() => isFetchingMore ? (
          <tr>
            <td className="listing pointer">
              <SkeletonRow type="listing" />
            </td>
            {dates.map((_, dateIndex) => (
              <td key={dateIndex}>
                <SkeletonRow type="calendar" />
              </td>
            ))}
          </tr>
        ) : null}
        fixedHeaderContent={() => (
          <tr>
            <th className="listings-header">
              <div className="d-flex">
                {loading ? (
                  <>
                    <div className="spinner-message"></div>
                    Properties Active
                  </>
                ) : (
                  `${allPropertyCount} Properties Active`
                )}
              </div>
            </th>
            {dates.map((date, index) => (
              <th
                key={index}
                className={`date-header-cell date-header-cell-${index} ${date === moment().format("YYYY-MM-DD") ? "today" : ""
                  }`}
              >
                <Moment format="ddd,  MMM D">{date}</Moment>
                {date === moment().format("YYYY-MM-DD") && (
                  <span className="today-label">Today</span>
                )}
              </th>
            ))}
          </tr>
        )}
        itemContent={(index, property) => {
          if (loading) {
            return (
              <>
                <td className="listing pointer">
                  <SkeletonRow type="listing" />
                </td>
                {dates.map((_, dateIndex) => (
                  <td key={dateIndex}>
                    <SkeletonRow type="calendar" />
                  </td>
                ))}
              </>
            );
          }

          const calendarPMSMap = listings[property.id] || {};
          const pageNumber = getPageNumber(index);

          return (
            <>
              <td className="listing pointer" onClick={() => window.open(`https://mypropertystack.io/property_details/${property.id}`, '_blank')}>
                <Listing key={property.id} listing={property} loading={loading} />
              </td>
              {dates.map((date, dateIndex) => {
                const normalizedDate = moment(date).format('YYYY-MM-DD');
                const jobList = getJobListForDate(listings, property.id, normalizedDate);

                // console.log("jobList for date", normalizedDate, jobList);
                return (
                  <DayCell
                    key={dateIndex}
                    date={date}
                    calendarPMSMap={calendarPMSMap}
                    property={property}
                    jobList={jobList}
                    onBookingClick={memoizedOnBookingClick}
                    onJobClick={memoizedOnJobClick}
                    onJobDetailClick={memoizedOnJobDetailClick}
                    onMoreJobDetailClick={memoizedOnMoreJobDetailClick}
                    onReservationClick={memoizedOnReservationClick}
                    isInRange={isDateInRange(date)}
                    newlyAddedReservationId={newlyAddedReservationId}
                    newlyAddedJobId={newlyAddedJobId}
                    pageNumber={pageNumber}
                  />
                );
              })}
            </>
          );
        }}
      />
    </div>
  );
});

export default Calendar;
